import React from 'react';
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";

class SideBar2 extends React.Component {
    constructor (props) {
      super(props)
      this.state = {
        menuOpen: false
      }
    }
  
    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    handleStateChange (state) {
      this.setState({menuOpen: state.isOpen})  
    }
    
    // This can be used to close the menu, e.g. when a user clicks a menu item
    closeMenu () {
      this.setState({menuOpen: false})
    }
  
    // This can be used to toggle the menu, e.g. when using a custom icon
    // Tip: You probably want to hide either/both default icons if using a custom icon
    // See https://github.com/negomi/react-burger-menu#custom-icons
    toggleMenu () {
      this.setState(state => ({menuOpen: !state.menuOpen}))
    }
  
    render () {
      return (
        <div>
          <Menu 
            isOpen={this.state.menuOpen}
            onStateChange={(state) => this.handleStateChange(state)}
          >
            <Link to="/" onClick={this.closeMenu}>JeannineCharles</Link>
            <Link to="/books" onClick={this.closeMenu}>Books</Link>
            <Link to="/words-on-a-thread" onClick={this.closeMenu}>Words On A Thread</Link>
            <Link to="/bio" onClick={this.closeMenu}>Bio</Link>
            <Link to="/contact" onClick={this.closeMenu}>Contact</Link>          
          </Menu>
        </div>
      )
    }
  }

  export default SideBar2;