import React from "react";
import styled from "styled-components";
import { device } from './Device';

const BooksCont = styled.div`
  background-image: url(/images/NRol.jpg);
  @media ${device.mobileM} { 
    height: 100vh;
  }
  @media ${device.mobileL} { 
      height: 100vh;
  }
  @media ${device.laptop} { 
      height: 110vh;
  }
`;

const BooksPhoto = styled.div`
  @media ${device.mobileM} { 
    font-size: 16px;
    img {
        max-width: 300px;
    }
  }
  @media ${device.mobileL} { 
    font-size: 18px;
    img {
        max-width: 400px;
    }
  }
  @media ${device.laptop} { 
    font-size: 24px;
    img {
      max-width: 600px;
    }
  }

  text-align-last: center;

  img {
    margin-top: 1.0em;
    padding-left: 1.0em;
    padding-right: 1.0em;
    margin-bottom: 0.0em;
  }
`;


const BooksTextCont = styled.div`
  @media ${device.mobileM} { 
      font-size: 14px;
  }
  @media ${device.mobileL} { 
      font-size: 18px;
  }
  @media ${device.laptop} { 
      font-size: 24px;
  }

	flex-flow: column wrap;
	min-height: 400px;
	font-size: 1.5em;
  text-align-last: center;
  margin-bottom: 1.0em;

  p {
    padding-left: 2.0em;
    padding-right: 2.0em;
    margin-bottom: 1.0em;
    color: #303030;
  }

	h3 {
    font-family: RieslingRegular;
    text-rendering: optimizeLegibility;
    font-weight: 300;
    color: #303030;
		font-size: 4.0em;
    margin-top: 0px;
		margin-bottom: 0px;
    text-align: center;
    text-align-last: center;
	}
	h4 {
		font-size: 1.25em;
    font-weight: 300;
    color: #303030;
    margin-top: 0px;
		margin-bottom: 1.0em;
    text-align: center;
    text-align-last: center;
	}
`;

export default function Books() {

    return(
        <BooksCont>
            <BooksPhoto>
                <img src='/images/9ROLtitlepxm_just_title.png' alt="Nine_Realms"></img> 
            </BooksPhoto>
            <BooksTextCont>
                <h4>A new exciting YA adventure fantasy by Jeannine Charles. </h4>
                <h4>NINE Realms. ONE Girl. BIG Problem. </h4>
                <p>Kieran El’Xantha would give anything to silence the voice in her head. A voice that insists she reclaim a gold and silver sword and escape an abusive bladesmith. Plagued with confusing images of a forgotten past, it would seem that she has no choice but to obey. </p>
                <p>Believing Kieran to be a young boy, Captain Mallow is impressed when he and Knight Andren Riva witness her take down five men using only a pole. Mallow suspects Kieran is an enemy of the Riven Kingdom but is willing to wait and find out more before killing him. He offers to send Kieran to Amstarhe Temple, promising the monks can restore his memory. </p>
                <p>Seventeen-year-old Andren Riva is not interested in babysitting some kid. He was on his first mission to recruit soldiers for the coming war and wants to prove his worth. It's hate at first sight. </p>
                <p>When they arrive at the temple, Mallow and Andren's perception of the world is shattered when Kiera's identity is revealed, and the horrific nature of the enemy is unveiled. Mallow, Andren, and Kieran learn they must unite the scattered realms before the SineAnirna destroy humanity from the inside out. </p>
                <p>But they've got to stop fighting each other first. </p>
                <p>NINE REALMS OF LIGHT is YA epic fantasy adventure for fans of Victoria Aveyard and Sara J. Maas.</p>
           </BooksTextCont>
        </BooksCont>
    )
}
