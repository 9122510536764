import Navbar from './Navbar';
import Home from './Home';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useState, useCallback } from 'react';
import SideBar2 from "./sidebar2";

import useWindowDimensions from './useWindowDimensions';
import Books from './Books';
import NotFound from './NotFound';
import BioPage from './BioPage';
import WordsOnAThread from './WordsOnAThread';
import Contact from './Contact';

const useCurrentPath = () => {
  const location = useLocation();
  return(location.pathname)
};

function App() {
  //const location = useLocation;
  //console.log(location);

  const {width : windowWidth, height : windowHeight} = useWindowDimensions();
  //const [menuDimensions, setMenuDimensions] = useState({width: 10, height: 10});
  const [{width: menuWidth, height: menuHeight}, setMenuDimensions] = useState({width: 10, height: 10});
  const menuBreakPoint = (windowWidth < 800);
  //console.log(menuBreakPoint);
  let siteBackground = 'linear-gradient(rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 1.0)), url(/images/JCdotNetBackground.jpg)';

  const handleMenuChange = useCallback((menuDims) => {
      setMenuDimensions(menuDims);
    },
    [],
  );

  const currentPath = useCurrentPath();
  switch (currentPath) {
    case "/books":
      break;    
    case "/contact":
      siteBackground = 'linear-gradient(rgba(226, 231, 235, 1.0), rgba(226, 231, 235, 1.0)), url(/images/contact_small.jpg)';
      break;
    case "/words-on-a-thread":
      siteBackground = 'linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(/images/WordsThread_small.jpg)';
      break;
    case "/bio":
      siteBackground = 'linear-gradient(rgba(255, 255, 255, 1.0), rgba(255, 255, 255, 0.5)), url(/images/JCdotNetBackground.jpg)';
      break;
    case "/":
      if (menuBreakPoint) {
        siteBackground = 'linear-gradient(rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), url(/images/splashbakcground_small.jpg)';
      } else {
        siteBackground = 'linear-gradient(rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), url(/images/splashbakcground_small.jpg)';        
      }
      break;
    default: 
      siteBackground = 'linear-gradient(rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 0.0)), url(/images/JCdotNetBackground.jpg)';
  }

  //console.log("App Screen Size: ", windowWidth, " x ", windowHeight);
  return (
    
      <div id="App" className="app" style={{ 
        backgroundImage: siteBackground, 
        backgroundPosition: 'top',
        //minHeight: 100%,
        backgroundSize: '100% auto',
        backgroundRepeat: 'repeat',
        //height: '500vw',
        width: '100vw',
        //backgroundBlendMode: siteBackgroundBlendMode,
        backgroundColor: 'rgb(255), 255, 255)',
        //filter: 'brightness(50%)',
      }}>
        {menuBreakPoint ? <SideBar2 pageWrapId={"page-wrap"} outerContainerId={"App"} /> : <Navbar handleMenuChange={handleMenuChange} windowWidth={windowWidth} />}
        <div id="page-wrap" className="content" style={{
          padding: '0 0 0 0',
          margin: '0 0 0 0'
        }}>
          <Routes>
            <Route path="/" element={<Home windowWidth={windowWidth} windowHeight={windowHeight} menuWidth={menuWidth} menuHeight={menuHeight}/>} />
            <Route path="/books" element={<Books />} />
            <Route path="/bio" element={<BioPage />} />
            <Route path="/words-on-a-thread" element={<WordsOnAThread />} />
            <Route path="/contact" element={<Contact />} />
            {/*<Route path="/create" element={<Create />} />*/}
            {/*<Route path="/blogs/:id" element={<BlogDetails />} />*/}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
  );
}

export default App;
