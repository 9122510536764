import React from "react";
import styled from "styled-components";
import { device } from './Device';

const PostItCont = styled.div`
    a {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        color: black;
        text-decoration: none;
    }
`;
/* Block Posts formatted for 3 columns on laptop and one column on mobile */

const BlogPostCont = styled.div`
    font-family: "Sacramento", serif;
    @media ${device.mobileM} { 
        font-size: 4vw;
        width: 60vw;
        margin-left: 15vw;
        height: 50vw;
    }
    @media ${device.mobileL} { 
        font-size: 4vw;
        width: 60vw;
        margin-left: 15vw;
        height: 50vw;
    }
    @media ${device.tablet} { 
        font-size: 1.75vw;
        width: 25vw;
        margin-left: 0vw;
        height: 20vw;
    }
    @media ${device.laptop} { 
        font-size: 1.75vw;
        width: 25vw;
        height: 20vw;
    }
    @media ${device.laptopL} { 
        font-size: 1.75vw;
        width: 25vw;
        height: 20vw;
    }
	flex-flow: column wrap;
	font-size: 1.5em;
    text-align-last: center;
    background: rgb(255,255,153);
    background: linear-gradient(135deg, rgba(255,255,153,1) 0%, rgba(255,255,153,1) 85%, rgba(53,53,32,1) 100%);
  
    margin-bottom: 2.5em;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
    a {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        color: black;
        text-decoration: none;
    }
    p {
        font-family: "Sacramento", serif;
        font-size: 1.25em;
        padding-left: 1.0em;
        padding-right: 1.0em;
        margin-bottom: 1.0em;
        color: #303030;
    }
	h4 {
        font-family: "Sacramento", serif;
		font-size: 1.5em;
        font-weight: 300;
        color: #303030;
        padding-top: 0.5em;
        margin-top: 0px;
		margin-bottom: 0.5em;
        text-align: center;
        text-align-last: center;
	}
`;

/* replace <a href={BlogURL}> with <a href={BlogURL} target="_blank"> to force broswer to open new tab with link */

export default function PostIt({BlogURL, BlogHeader, BlogDetails} ) {
    return (
        <PostItCont>
            <a href={BlogURL}>
                <BlogPostCont>
                    <h4>{BlogHeader}</h4>
                    <p>{BlogDetails}</p>
                </BlogPostCont>
            </a>
       </PostItCont>
    );
  }
