import React from "react";
import Column from "./Column";
import Grid from "./Grid";
import styled from "styled-components";
import { device } from './Device';
import PostIt from "./PostIt";

const WoatCont = styled.div`
    margin-top: 1.0em;
    padding-left: 5vw;
    width: 95vw;
    @media ${device.mobileL} { 
    }
    @media ${device.tablet} { 
        height: 100vh;
    }
`;

const HeadingTextCont = styled.div`
    @media ${device.mobileM} { 
        font-size: 14px;
    }
    @media ${device.mobileL} { 
        font-size: 18px;
    }
    @media ${device.laptop} { 
        font-size: 24px;
    }

    flex-flow: column wrap;
    font-size: 1.5em;
    text-align-last: center;

    p {
        padding-left: 0.5em;
        padding-right: 0.5em;
        margin-bottom: 1.0em;
        color: #303030;
    }
    h4 {
        font-size: 1.75em;
        font-weight: 300;
        color: #303030;
        margin-top: 0px;
        margin-bottom: 1.0em;
        text-align: center;
        text-align-last: center;
    }
    a {
        color: blue;
    }
`;

export default function WordsOnAThread() {

    return(
        <WoatCont>
            <HeadingTextCont>
                <img src='/images/WordthreadstitleOnly.png' alt="Text_Overlay"></img> 
                <p>Jeannine Charles' entertaining blog about her writing journey mixed with quirky stories from her youth.</p>
                <p>For a complete list of articles, visit her blog, <a href="https://jeanninecharlesbooks.blogspot.com">Words on a Thread, Dangling Thoughts and Other Incomplete Revelations</a>, or check out the featured articles below.</p>
            </HeadingTextCont>
            <Grid>
                <Column className="s">
                    <PostIt BlogURL={"https://jeanninecharlesbooks.blogspot.com/2021/11/remembering-old-friends-rediscovering.html"}
                        BlogHeader={"Write From The Start"}
                        BlogDetails={"Remembering Old Friends &  Rediscovering My Writing Roots"}
                    />
                </Column>
                <Column className="s">
                    <PostIt BlogURL={"https://jeanninecharlesbooks.blogspot.com/2021/11/the-cold-floridian.html"}
                        BlogHeader={"The Cold Floridian"}
                        BlogDetails={"Scribbles in the Car"}
                    />
                </Column>
                <Column className="s">
                    <PostIt BlogURL={"https://jeanninecharlesbooks.blogspot.com/2021/11/shoot-ready-aim.html"}
                        BlogHeader={"Shoot, Ready, Aim"}
                        BlogDetails={"Bike Rides & Fighter Jets"}
                    />
                </Column>
                <Column className="s">
                    <PostIt BlogURL={"https://jeanninecharlesbooks.blogspot.com/2021/11/filters-are-for-fish-tanks.html"}
                        BlogHeader={"Filters Are For Fish Tanks"}
                        BlogDetails={"Border Line Decisions~"}
                    />
                </Column>
                <Column className="s">
                    <PostIt BlogURL={"https://jeanninecharlesbooks.blogspot.com/2021/11/world-of-shadow.html"}
                        BlogHeader={"World Of Shadow"}
                        BlogDetails={"Voice Hunter~"}
                    />
                </Column>
                <Column className="s">
                    <PostIt BlogURL={"https://jeanninecharlesbooks.blogspot.com/2021/12/dancing-through-doorways.html"}
                        BlogHeader={"Dancing Through Doorways"}
                        BlogDetails={"The Room At The End of The Hall~"}
                    />
                </Column>
                <Column className="s">
                    <PostIt BlogURL={"https://jeanninecharlesbooks.blogspot.com/2021/12/diving-for-treasure.html"}
                        BlogHeader={"Diving For Treasure"}
                        BlogDetails={"Clouds, Sharks, & Joy"}
                    />
                </Column>
                <Column className="s">
                    <PostIt BlogURL={"https://jeanninecharlesbooks.blogspot.com/2021/12/walking-backward-into-danger.html"}
                        BlogHeader={"The Common Sense Assassin"}
                        BlogDetails={"Hitting Snooze"}
                    />
                </Column>
                <Column className="s">
                    <PostIt BlogURL={"https://jeanninecharlesbooks.blogspot.com/2022/01/chain-of-habits.html"}
                        BlogHeader={"Chain Of Habits"}
                        BlogDetails={"Ice, Please ~"}
                    />
                </Column>
                <Column className="s">
                    <PostIt BlogURL={"https://jeanninecharlesbooks.blogspot.com/2022/01/chain-of-habits.html"}
                        BlogHeader={"Chain Of Habits"}
                        BlogDetails={"Ice, Please ~"}
                    />
                </Column>
                <Column className="s">
                    <PostIt BlogURL={"https://jeanninecharlesbooks.blogspot.com/2022/03/connecting-in-quiet.html"}
                        BlogHeader={"Connecting In The Quiet"}
                        BlogDetails={"A moment of silence"}
                    />
                </Column>
                <Column className="s">
                    <PostIt BlogURL={"https://jeanninecharlesbooks.blogspot.com/2022/03/perfect-timing.html"}
                        BlogHeader={"Perfect Timing"}
                        BlogDetails={"Awkward is as Awkward does..."}
                    />
                </Column>
                <Column className="s">
                    <PostIt BlogURL={"https://jeanninecharlesbooks.blogspot.com/2022/01/the-unconventional-cope-ist.html"}
                        BlogHeader={"The Unconventional Cope-Ist"}
                        BlogDetails={"The Secrets We Keep~"}
                    />
                </Column>
            </Grid>
        </WoatCont>
    )
}


