import React from "react";
import Column from "./Column";
import Grid from "./Grid";
import styled from "styled-components";
import { device } from './Device';

const BioPhoto = styled.div`

text-align-last: center;
    img {
        margin-top: 1.0em;
        padding-left: 1.0em;
        padding-right: 1.0em;
        margin-bottom: 0.0em;
        max-height: 78vw;
    }

    @media ${device.mobileL} { 
        img {
            max-height: 400px;
        }
    }
`;

const BioCont = styled.div`
    @media ${device.mobileL} { 
    }
    @media ${device.tablet} { 
        height: 100vh;
    }
`;

const BioTextCont = styled.div`
    font-size: 12px;
    @media ${device.mobileS} { 
        font-size: 12px;
    }
    @media ${device.mobileM} { 
        font-size: 14px;
    }
    @media ${device.mobileL} { 
        font-size: 14px;
    }
    @media ${device.laptop} { 
        font-size: 16px;
    }

	flex-flow: column wrap;
	min-height: 400px;
	font-size: 1.5em;
    text-align-last: center;

    p {
        padding-left: 2.0em;
        padding-right: 2.0em;
        margin-bottom: 1.0em;
        color: #303030;
        font-size: 1.25em;
    }

	h3 {
        font-family: RieslingRegular;
        text-rendering: optimizeLegibility;
        font-weight: 300;
        color: #303030;
		font-size: 4.0em;
        margin-top: 0px;
		margin-bottom: 0px;
        text-align: center;
        text-align-last: center;
	}
	h4 {
		font-size: 1.75em;
        font-weight: 300;
        color: #303030;
        margin-top: 0px;
		margin-bottom: 1.0em;
        text-align: center;
        text-align-last: center;
	}
`;


const FollowTextCont = styled.div`
    img {
        height: 1.25em;
        padding-left: 0.75em;
        padding-right: 0.75em;
    }

    @media ${device.mobileM} { 
        font-size: 16px;
        img {
            padding-left: 1.25em;
            padding-right: 1.25em;
        }
    }
    @media ${device.mobileL} { 
        font-size: 18px;
        img {
            padding-left: 1.25em;
            padding-right: 1.25em;
        }
    }
    @media ${device.laptop} { 
        font-size: 18px;
        img {
            height: 1.25em;
            padding-left: 0.75em;
            padding-right: 0.75em;
        }
    }

	flex-flow: column wrap;
	min-height: 400px;
	font-size: 1.0em;
    text-align-last: center;

    p {
        padding-left: 2.0em;
        padding-right: 2.0em;
        margin-bottom: 1.0em;
        font-size: 1.0em;
        color: #303030;
    }

    a {
        color: blue;
    }
`;

export default function BioPage() {

    return(
        <Grid>
			<Column className="l">
                <BioCont>
                    <BioPhoto>
                        <img src='/images/jeanninebio.jpg' alt="Bio"></img> 
                    </BioPhoto>
                    <BioTextCont>
                        <h3>Jeannine Charles</h3>
                        <h4>Writer</h4>
                        <p>For over thirty years, Jeannine Charles, Artistic Director of ArtistEDGE, has used her body to tell stories, creating professional modern dance choreography that breaks artistic edges, collaborating with like-minded musicians, dancers, singers, poets, stilt artists, and engineers.</p>
                        <p>With a career centered on nurturing off-the-wall ideas, Ms. Charles is now focused on bringing those ideas to life on the page as well as the stage.</p>
                    </BioTextCont>
                </BioCont>
			</Column>
			<Column className="s">
                <FollowTextCont>
                    <p>Follow me on Facebook or Twitter and swirl some stories into your coffee!</p>
                    <a href="https://m.facebook.com/jeanninecharleswrites"><img src='/images/2021_Facebook_icon.svg' alt="qqq"></img></a> <a href="https://twitter.com/j9charles"><img src='/images/Logo_of_Twitter.svg' alt="qqq"></img></a>
                    <p>Jeannine Charles has been creating art in one form or another since she could walk. At the heart of it all have been her stories.</p>
                    <p>As Artistic Director of ArtistEDGE, Jeannine collaborated with Gordon Charles and won an award for innovation for her modern dance piece, Light(e)scapes.  You can watch an excerpt <a href="https://vimeo.com/245095318"> here</a>.</p>
                    <p>Jeannine wrote and co-produced an album blending rock, pop and jazz.</p>
                    <p>The curious can listen to the album for free at <a href="https://open.spotify.com/artist/3cSpvfGR1SGJh3UbKhcmWA ">Spotify</a>.</p>
                </FollowTextCont>
			</Column>
        </Grid>
    )
}


