import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { useEffect, useRef } from "react";

const Navbar = (props) => {
  const {handleMenuChange, windowWidth} = props;
  const refContainer = useRef();
  //console.log("Inside Navbar: ", props);
  useEffect(() => {
    //console.log("Inside useEffect: ", props);
    if (refContainer.current) {
      handleMenuChange({
        width: refContainer.current.offsetWidth,
        height: refContainer.current.offsetHeight
      });
    }
  }, [handleMenuChange, windowWidth]);

  return (
    <nav className="navbar" ref={refContainer}>
      {/*<h1>Unused</h1>*/}
      <ul className="menu">
          <ListLink to="/">JeannineCharles</ListLink>
          <ListLink to="/books">Books</ListLink>
          <ListLink to="/words-on-a-thread">Words on a Thread</ListLink>
          <ListLink to="/bio">Bio</ListLink>
          <ListLink to="/contact">Contact</ListLink>
          {/*<Link to="/create" style={{ 
            color: '#00ff00', 
            backgroundColor: '#f1356d',
            borderRadius: '8px' 
          }}>New Blog</Link>*/}
      </ul>
    </nav>
  );
}
 
function ListLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({path: resolvedPath.pathname, end: true});
  return (
      <Link className={isActive ? "active" : ""} to={to} {...props}>
        {children}
      </Link>
  )
}

export default Navbar;