import styled from "styled-components";
import { device } from './Device';
//import ceil from Math;

const BookHeadingCont = styled.div`
  margin-bottom: 0rem;
  text-align: center;
  text-align-last: center;
  @media ${device.mobileS} { 
    font-size: 18px;
    padding-top: 30px;
  }
  @media ${device.mobileM} { 
    font-size: 18px;
    padding-top: 30px;
  }
  @media ${device.mobileL} { 
    font-size: 18px;
    padding-top: 30px;
  }
 
  @media ${device.laptop} { 
    font-size: 24px;
  }

  p {
      padding-left: 2.0em;
      padding-right: 2.0em;
      margin-bottom: 1.0em;
      color: #303030;
      font-size: 1.0em;
      text-align: center;
      text-align-last: center;
  }

  h2 {
    font-size: 4.0em;
    margin-top: 0.75em;
    margin-bottom: 0.5em;
    line-height: 0.025em;
    color: #303030;
  }
  h3 {  
      font-family: RieslingRegular;
      text-rendering: optimizeLegibility;
      font-weight: 300;
      font-size: 4.0em;
      margin-top: 0.75em;
      margin-bottom: 0.5em;
      line-height: 0.25em;
      color: #303030;
  }
  h4 {
    font-size: 1.75em;
    font-weight: 300;
    margin-bottom: 1.0em;
    color: #303030;
  }
  h4b {
    font-size: 2.25em;
    font-weight: 300;
    margin-bottom: 1.0em;
    color: #303030;
}
`;

const BookTextCont = styled.div`
  @media ${device.mobileL} { 
    font-size: 18px;
  }

  @media ${device.laptop} { 
    font-size: 24px;
  }

  p {
      padding-left: 2.0em;
      padding-right: 2.0em;
      margin-bottom: 1.0em;
      text-align: left;
      text-align-last: left;
      color: #303030;
  }
  i {
    color: #303030;
  }
`;

const Home = ({windowWidth, windowHeight, menuWidth, menuHeight}) => {
  const backgroundImageWidth = 1920;  // When loading from firebase these should be replaced with values in DB
  const backgroundImageHeight = 1164;
  const videoOffsetFromBackground = 10;
  const videoVerticalOffsset = (windowWidth > 799) ? Math.ceil(backgroundImageHeight / backgroundImageWidth * windowWidth + videoOffsetFromBackground - menuHeight) :
                                                     Math.ceil(backgroundImageHeight / backgroundImageWidth * windowWidth + videoOffsetFromBackground);
  //console.log(videoVerticalOffsset);

  return (
    <div className="home">      
      <BookHeadingCont>
        <h3>Jeannine Charles</h3>
        <h4>Writer</h4>
        <h4b>Nine Realms of Light</h4b>
        <h4>The Prologue</h4>
      </BookHeadingCont>
      <BookTextCont>
        <p>“Love is for those with no greater purpose,” King Caecus muttered as he paced along the bluff overlooking Corriven’s harbor. He had to see for himself that his meddlesome sister had boarded her ship and set sail. He'd had enough of her whispered words and intrigues.</p>
        <p>“I won't do it!” the King of Riven yelled over the wind, imaging his words could fly across the waves and pummel the stubborn woman’s ears. He raked his hands through his black and silver-streaked hair as if he could uproot the insecurities she had planted there. “I did not save the Riven people from my bloodthirsty father to have them destroyed by outlanders!”</p>
        <p>“Your majesty?” a man suddenly said.</p>
        <p>“What?” he lashed out, words uncoiling like a whip as he turned to face the interloper.</p>
        <p>“Beg your pardon, your majesty,” Knight Oden replied, swallowing hard, “but Master Niko’s men have found more shipwrecks.”</p>
        <p>The knight’s silver armor gleamed brightly in the noonday sun, but Caecus would not show weakness by shielding his eyes. “Any survivors?”</p>
        <p>“None, your majesty,” Oden replied. “Only a few dozen waterlogged corpses with golden hair and strange uniforms.”</p>
        <p>“Tell Niko to keep looking,” Caecus said, waving the knight away. He walked along the cliff’s edge until he had an unobstructed view and watched Calinda’s ship sail up the coast.</p>
        <p>“Your majesty?” the knight said quietly as if fearing the king would throw him off the cliff for disturbing him.</p>
        <p>“You still here?” Caecus grumbled. “I gave you an order, Sir Oden. Go away.” He looked down at the broken vessels littering his shores. Evidence of a massive slaughter sat on his doorstep, and the noose of responsibility was tightening. Sweat trickled down his back, and he loosened the stiff collar of his shirt—</p>
        <p>Oden cleared his throat and said quickly, “While Master Niko and his men found no survivors, there is a <i>rumor</i> of one.”</p>
        <p>Caecus turned slowly, his towering height casting a long shadow over the knight. “What sort of rumor?”</p>
        <p>Oden paled. “One of the scavengers we arrested claimed to have seen a strange child wandering about the wreck—”</p>
        <p>“A child?” Caecus cut in. “Outrageous! Those ships were made for battle, not transport. There has been no indication of anything other than a military presence aboard those ships.” Disappointment leached into his leather boots and traveled up to his thin, pursed lips. He’d seen the charred bodies, and judging by their size, there wasn’t a child among them. Whatever had attacked the foreign soldiers had left black spidery trails across their skin as if they had been burned from the inside out…</p>
        <p>Caecus walked away, deeming Oden’s information useless, and returned his thoughts to Calinda. She had no right to make him second guess his relationship with his son.</p>
        <p>“But, your majesty,” Oden said, treading two steps behind, “the thief claimed the child possessed a sword fit for a king with symbols etched along the blade.”</p>
        <p>Caecus froze mid-step. “Impossible!” He had kept the kingdom hidden, his people safe, eliminating outside threats—was it all in vain? Had their ancient enemy finally found them? Waves of heat rolled off his body. He wiped the sweat from his brow as rage and fear hummed an archaic melody.</p>
        <p><i>Set the world alight, </i></p>
        <p><i>Glowing, burning, </i></p>
        <p><i>Illuminate the sight </i></p>
        <p><i>Of a forgotten foe </i></p>
        <p>At the sudden clanging of the knight’s heavy armor, Caecus turned.</p>
        <p>The knight’s body had burned to ash.</p>
      </BookTextCont>
    </div>                                                                                        
  );
}
/*
<p>“Love is for those with no greater purpose,” King Caecus muttered as he paced along the bluff overlooking Corriven’s harbor. He had to see for himself that his meddlesome sister had boarded her ship and set sail. He'd had enough of her whispered words and intrigues.</p>
<p>“I won't do it!” the King of Riven yelled over the wind, imaging his words could fly across the waves and pummel the stubborn woman’s ears. He raked his hands through his black and silver-streaked hair as if he could uproot the thoughts she had planted there. “I did not save the Riven people from my bloodthirsty father to have them destroyed by outlanders!”</p>
<p>“Your majesty?” a man suddenly said from behind him.</p>
<p>“What?” Caecus lashed out, turning to face the intruder, his words uncoiling like a whip.</p>
<p>“Beg your pardon, your majesty,” Knight Oden replied, swallowing hard, “but Master Niko’s men have found more foreign ships.”</p>
<p>The knight’s silver armor gleamed brightly in the noonday sun, but Caecus would not show weakness by shielding his eyes. “Any survivors?”</p>
<p>“None, your majesty,” Oden replied. “Only a few dozen waterlogged corpses with golden hair and strange uniforms were pulled from the wreckage.”</p>
<p>“Tell Niko to keep looking,” Caecus said, waving the knight away. He walked along the cliff’s edge until he had an unobstructed view of the coast and watched Calinda’s ship said up the Krillock Coast and out of sight.</p>
<p>The knight hesitated before following at a respectable distance. “Your majesty?”</p>
<p>“You still here?” Caecus grumbled. “I gave you an order, Sir Oden.” He looked down at the broken vessels littering his shores. Evidence of a massive slaughter sat on his doorstep, and Caecus could not keep the noose of responsibility from tightening. Sweat trickled down his back. He loosened the stiff collar of his shirt—</p>
<p>“Pardon me, your majesty?”</p>
<p>Caecus started, believing the knight had left. “What, now?” he demanded, keeping his eyes on the sea.</p>
<p>“While Master Niko and his men found no survivors, there is a <i>rumor</i> of one,” Oden said quickly.</p>
<p>Caecus slowly turned around, his towering height casting a long shadow over the knight. “What sort of rumor?”</p>
<p>Oden paled. “One of the scavengers we arrested claimed to have seen a strange child wandering about the wreckage—”</p>
<p>“A child?” Caecus cut in. “Outrageous! Those ships were made for battle, not transport. There has been no indication of anything other than a military presence aboard those ships.” Disappointment leached into his leather boots and traveled up to his thin, pursed lips. He had seen the charred bodies, and judging by their size, there wasn’t a child among them. Whatever had attacked the foreign soldiers had left black spidery trails across their skin as if they had been burned from the inside out.</p>
<p>Caecus walked away, deeming Oden’s information useless, and returned his thoughts to Calinda. She had no right to make him second guess his relationship with his son.</p>
<p>“But, your majesty,” Oden said, treading two steps behind, “the thief claimed the child possessed a sword fit for a king and swore that there were ancient symbols etched all along the blade.”</p>
<p>Caecus froze mid-step. “Impossible!” <i>I have kept the kingdom hidden, my people safe, eliminating outside threats—was it all in vain?</i>  His heart began to race and an intense heat emanated from his body.</p>
<p>The temperature rose sharply inside Oden’s armor, and sweat cascaded down his face as scorching waves of heat rolled off the king. Oden stumbled backward, crashing into the dirt.</p>
<p>The clanging of the knight’s heavy armor drew Caecus' attention, and he looked down at Oden flailing on the ground. “No time for a nap, Sir Oden— we have plans to make!”</p>
<p>“Yes, your majesty,” Oden gasped, struggling to his feet.</p>
<p>The urgency that had plagued Caecus for months would have him rushing back to the castle but caution’s heavy hand spun him around. “You <i>are</i> good at keeping secrets, aren’t you, Sir Oden?”</p>
<p>It would be unfortunate if he had to throw a perfectly capable knight off the bluff and into the Nevar Sea.</p>
*/
export default Home;
