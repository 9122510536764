import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Reaptcha from 'reaptcha';
import styled from "styled-components";
import { device } from './Device';

const ContactCont = styled.div`
  background: rgba(226,231,235,0); 
`;
const FormCont = styled.div`
  @media ${device.mobileS} { 
    font-size: 16px;
    padding-top: 50px;
  }
  @media ${device.mobileM} { 
    font-size: 16px;
    padding-top: 50px;
    input, textarea {
      font-size: 1.0em;
    }
  }
  @media ${device.mobileL} { 
    font-size: 18px;
    padding-top: 50px;
    input, textarea {
      font-size: 1.0em;
    }
  }
  @media ${device.tablet} { 
    padding-top: 2.5vh;
  }
  @media ${device.laptop} { 
    font-size: 24px;
    padding-top: 2.5vh;
    input, textarea {
      font-size: 0.75em;
    }
  }
  background: rgba(0,0,0,0);
  padding-left: 10vw;  
  width: 90vw;
  label {
    color: black;
  }

  input, textarea {
    width: 100%;
    border: 1;
    padding: 10px 10px;
    box-sizing: border-box;
    margin-bottom: 15px;
  }
  p, label, input, select, textarea {
    color: #000000;
  }
  p {
    text-align-last: center;
    }
    
  }
  textarea {
    height: 100px;
    resize: vertical;
  }
  input[type=select] {
    font-size: 2.0vw;
  }
  input[type=submit][disabled] {
    background-color: #888888;
  }
  input[type=submit][enabled] {
    background-color: #0000FF;
  }
`;


const ImageCont = styled.div`
  @media ${device.mobileS} { 
    font-size: 16px;
  }
  @media ${device.mobileM} { 
    font-size: 16px;
  }
  @media ${device.mobileL} { 
    font-size: 18px;
  }
  @media ${device.tablet} { 
    height: 100vh;
  }
  @media ${device.laptop} { 
    font-size: 24px;
  }

img {
  width: 100%;
}
`;

export const Contact = () => {
  const form = useRef();
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [invisibleCaptcha, setInvisibleCaptcha] = useState(null);
  const [messageSentNotification, setMessageSentNotification] = useState((<span>&nbsp;</span>)); 
  const spacer = (<span>&nbsp;</span>);

  const onVerify = recaptchaResponse => {
    setCaptchaVerified(true);
    //console.log("Verified");
  }

  const sendEmail = (e) => {
    e.preventDefault();

    const email = form.current.reply_to.value;
    const isValidEmail = email => {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(String(email).toLowerCase());
    };

    const valid_email = isValidEmail(email);

    if (!valid_email) {
      alert('Please enter a valid e-mail address.');
    } else if (!form.current.from_name.value) {
      alert('Please provide a contact name.');
    } else if (!form.current.message.value) {
      alert('Please provide a message.');
    } else {
  
    emailjs.sendForm('service_1czvat3', 'template_1pp8k8y', form.current, 'oNm-m0oyt3LKZKj0D')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    }
    setCaptchaVerified(false);
    document.getElementById("contact-form").reset();
    invisibleCaptcha.reset();
    setMessageSentNotification("Message sent at: " + Date().toLocaleString());
  };

  return (
    <ContactCont>
      <FormCont>
        <form id="contact-form" ref={form} onSubmit={sendEmail}>
          <p>Have questions or comments about my work?</p>
          <p>Just fill out the form below.</p>
          <p>I'd love to hear from you!</p>
          <label>Name</label>
          <input type="text" name="from_name" />
          <label>Email</label>
          <input type="email" name="reply_to" />
          <label>Message</label>
          <textarea name="message" />
          <Reaptcha 
            ref={e => setInvisibleCaptcha(e)}
            sitekey="6Lc0gz4nAAAAAOAGow2VA-r3P03ZKW6SUmVO63sz"
            onVerify={onVerify} 
            theme='dark'
            badge='bottomleft'
          />
          <label>{messageSentNotification}</label>
          <input type="submit" disabled={!captchaVerified} value="Send" />
        </form>
      </FormCont>
      <ImageCont>
        <img src='/images/contact_small_under.jpg' alt="Pen"></img> 
      </ImageCont>
    </ContactCont>
  );
};

export default Contact;

